.app-container {
  .app-sidebar {
    // background-color: aqua;
    border-right: solid 1px #519494;

    .app-logo {
      //   background-color: blue;
      padding: 20px 20% 20px 20%;
    }
  }
  .app-content {
    // background-color: aquamarine;
    padding: 30px;
  }
}

.app-container-full {
  //   background-color: #519494;
}
